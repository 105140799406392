import request from '@/utils/requests'
import qs from 'qs'

// 榜单列表
export const topicList = data => {
    return request({
        url: '/topic/list',
        method: 'post',
        data: qs.stringify(data)
    })
}

// 榜单影片列表
export const topicVodList = data => {
    return request({
        url: '/topic/vod_list',
        method: 'post',
        data: qs.stringify(data)
    })
}
